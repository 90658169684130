import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const AdminPage = lazy(() =>
	import("./admin/AdminPage")
);

const ClientePage = lazy(() =>
	import("./cliente/ClientePage")
);

const MetricaPage = lazy(() =>
	import("./metrica/MetricaPage")
);

const NoticiaPage = lazy(() => 
	import("./noticia/NoticiaPage")
);

const NotificacaoPage = lazy(() =>
	import("./notificacao/NotificacaoPage")
);

const PesquisaPage = lazy(() => 
	import("./pesquisa/PesquisaPage")
);

const UsuarioPage = lazy(() =>
	import("./usuario/UsuarioPage")
);

const TagPage = lazy(() =>
	import("./tag/TagPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/admin" component={AdminPage} />
				<Route path="/cliente/:id/metrica" component={MetricaPage} />
				<Route path="/cliente" component={ClientePage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/notificacao" component={NotificacaoPage} />
				<Route path="/noticia" component={NoticiaPage} />
				<Route path="/pesquisa" component={PesquisaPage} />
				<Route path="/usuario" component={UsuarioPage} />
				<Route path="/tag" component={TagPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
