export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "Clientes",
				root: true,
				icon: "fas fa-building",
				page: "cliente",
				bullet: "dot"
			},
			{
				title: "Usuários",
				root: true,
				icon: "fas fa-users",
				page: "usuario",
				bullet: "dot"
			},
			{
				title: "Notícias",
				root: true,
				icon: "fas fa-newspaper",
				page: "noticia",
				bullet: "dot"
			},
			{
				title: "Pesquisas",
				root: true,
				icon: "fas fa-poll",
				page: "pesquisa",
				bullet: "dot"
			},
			{
				title: "Notificações",
				root: true,
				icon: "fas fa-bell",
				page: "notificacao",
				bullet: "dot"
			},
			{
				title: "Tags",
				root: true,
				icon: "fas fa-tags",
				page: "tag",
				bullet: "dot"
			},
			{
				title: "Administradores",
				root: true,
				icon: "fas fa-user-shield",
				page: "admin",
				bullet: "dot"
			},
			{
				title: "Grupos de Acesso",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
		]
	}
};
